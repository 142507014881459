* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#root {
  max-height: 100vh;
}

/* sizing of map container */
.leaflet-container {
  position: absolute;
  top: 0;
  left: 0;
  /* width: 100%; */
  width: 100vw;
  z-index: 1;
  height: 100vh;
  /* border-radius: 10px; */
}
