@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body,
#root,
.app .content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.stripes {
  width: 300px;
  height: 200px;
  background: repeating-linear-gradient(
    45deg,
    /* Angle of the stripes */ rgba(218, 30, 40, 0.5) 10%,
    /* First color and width of stripes */ rgba(255, 131, 43, 0.5) 10%,
    /* Second color */ rgba(255, 131, 43, 0.5) 20%,
    /* Same second color to complete the stripe */ rgba(218, 30, 40, 0.5) 20%
      /* First color completes the next stripe */
  );
}

.app {
  display: flex;
  position: relative;
  max-width: 100% !important;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on Hover */
::webkit-scrollbar-track:hover {
  background: #555;
}
